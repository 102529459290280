$body-bg: #F6F6F6;
$primary: #e09900;
$secondary: #2a4a55;
$tertiary: #e09900;
$form_bg_color: #f4cd6b;

$call_to_action_bg: #e09900;
$call_to_action_text: white;

$body-color: #444;
$headings-color: $tertiary;
$search-home-bg-color: $form_bg_color;
$search-overview-bg-color: $form_bg_color;
$header-bg-color:#f7f7f7;
$navbar-dark-color: white;
$navbar-dark-hover-color: lighten($primary,40);
$mobile-header-color:$primary;
$mobile-header-background:white;
$footer-background-color:#F9F9F9;
$bottom-footer-bg-color: #F9F9F9;
$footer-text-color:$body-color;
$footer-border-top:1px solid #ddd;
$footer-text-color-header: $tertiary;
$font-weight-normal: 300;
$navbar-font-weight: 600;

@import url('https://fonts.googleapis.com/css?family=Comfortaa|Open+Sans:400,400i,600&display=swap');

$font-family-sans-serif: 'Open Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Open Sans';
$btn-font-family: 'Comfortaa';
$headings-font-family: 'Comfortaa';

@import "../../flean_base_2019/sass/imports";

.static-header .container {
  .search h1 {
    font-size: 2.3rem;
    font-weight: bold !important;
  }

  .static-area {
    p {
      font-size: 1.25rem;
    }

    ul li {
      margin: 0.75rem 0;
    }
  }
}
